import React, { FunctionComponent, ReactNode } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import customTheme from '../../components/elements/prism.theme';
import type { Element } from 'hast';

export type CodeBlockProps = {
  node: Element;
  inline?: boolean;
  className?: string;
  children: ReactNode[];
};

const CodeBlock: FunctionComponent<CodeBlockProps> = ({
  node,
  inline,
  className,
  children,
  ...props
}) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      children={String(children).replace(/\n$/, '')}
      style={customTheme}
      language={match[1]}
      PreTag="div"
      {...props}
    />
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

const preStyles = {
  fontSize: '0.8rem',
};

export default CodeBlock;
