import { API } from '@/utils/api';

interface GetBlogLoveRes {
  love_count: number;
}

interface AddBlogLoveRes {
  data: {
    love_count: number;
  };
}

export const getBlogLove = async (slug: string) => {
  const { data } = await API.get<GetBlogLoveRes>(
    `/get-blog-love?slug=${encodeURIComponent(slug)}`,
  );
  return data;
};

export const addBlogLove = async (slug: string) => {
  const { data } = await API.put<AddBlogLoveRes>(
    `/add-blog-love?slug=${encodeURIComponent(slug)}`,
  );
  return data;
};
