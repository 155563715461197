import React, { FunctionComponent } from 'react';

interface HeartProps {
  fill?: string;
}

const Heart: FunctionComponent<HeartProps> = ({ fill = 'currentColor' }) => {
  return (
    <svg
      className="w-10"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.425 6.18478C29.0079 6.18478 26.2197 8.33448 24.6015 10.2783C22.9833 8.33448 20.1955 6.18478 15.7768 6.18478C8.25518 6.18478 3.20299 13.1526 3.20299 19.6599C3.20299 29.133 21.3994 41.8291 23.4742 43.2453C23.814 43.4768 24.2078 43.5928 24.6015 43.5928C24.9952 43.5928 25.389 43.4768 25.7288 43.2453C27.8036 41.8291 46 29.133 46 19.6599C46 13.1526 40.947 6.18478 33.425 6.18478Z"
        fill={fill}
      />
    </svg>
  );
};

export default Heart;
