import { addBlogLove, getBlogLove } from '@/services/love-blog-service';

import React, {
  useEffect,
  useState,
  FunctionComponent,
  MouseEvent,
} from 'react';
import Heart from '../icons/heart';

interface LoveButtonProps {
  slug: string;
}

const LoveButton: FunctionComponent<LoveButtonProps> = ({ slug }) => {
  const [loveCount, setLoveCount] = useState(0);

  useEffect(() => {
    async function initBlogLove() {
      try {
        const blogLove = await getBlogLove(slug);

        if (blogLove) {
          setLoveCount(blogLove.love_count);
        }
      } catch (err) {
        console.error(err);
      }
    }

    initBlogLove();
  }, []);

  const onAddBlogLove = async (event: MouseEvent) => {
    event.preventDefault();

    const blogLove = await addBlogLove(slug);
    setLoveCount(blogLove.data.love_count);
  };

  return (
    <div className="ml-4 flex flex-row gap-1 justify-end items-center">
      <button
        onClick={onAddBlogLove}
        className="transform  text-third hover:text-gradientred"
      >
        <Heart />
      </button>
      <span className="w-10 dark:text-textlightblue">{loveCount}</span>
    </div>
  );
};

export default LoveButton;
